import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { FadeIn } from "./animation"
import { fadeInUp } from "./animation/fade"
// import eco from "../images/pattern1.png"
import eco from "../images/eco.png"
import paperbag from "../images/paperbags-hero.webp.png"
import { Marker } from "../utils"

function Hero() {
  return (
    <div
      id="home"
      className="bgGPrimary relative overflow-hidden pt-[80px] md:pt-[100px] lg:pt-[130px]"
    >
      <div className="absolute w-full h-full right-0 top-0 bg-gradient-to-b from-transparent to-lime-300 z-20 opacity-50 lg:hidden"></div>

      <div className="container relative pt-24 pb-8 lg:pt-0 lg:pb-0">
        <div className="flex flex-wrap items-center text-center lg:text-left">
          <div className="w-full lg:w-1/2">
            <div className="absolute lg:relative z-10 lg:z-10 mx-auto lg:max-w-xl translate-x-12 translate-y-9 md:-translate-y-12 lg:translate-x-0 lg:translate-y-0 scale-[1.6] md:scale-125 lg:scale-100">
              <div className="mb-8 md:mb-24">
                <img
                  src={paperbag}
                  alt="hero"
                  className="mx-auto h-full max-w-full object-contain lg:max-h-full"
                />
              </div>
            </div>
            <div className="absolute left-0 bottom-0 w-full h-full flex justify-start items-end z-0 opacity-30 md:opacity-70 -translate-x-8 -translate-y-40">
              <img
                src={eco}
                alt="eco"
                className="w-full md:w-1/2 h-full object-contain object-bottom scale-150"
              />
            </div>
          </div>
          <div className="w-full md:px-4 lg:w-1/2 lg:pl-10 xl:pl-16 z-30">
            <FadeIn tag="div" className="hero-content">
              <motion.h1
                variants={fadeInUp}
                className="mb-8 font-lf text-6xl text-green-950 xl:text-7xl font-extrabold tracking-tighter leading-none md:space-x-2 text-left"
              >
                <span className="block">Crafting</span>
                <div className="inline-block">
                  <Marker color="yellow" rotate="clockwise">
                    paper bags
                  </Marker>
                  <span className="ml-2">&</span>
                </div>
                <span className="block">paper products</span>
                <div className="inline-block space-x-2">
                  <span>is</span>
                  <span>our</span>
                  <span>forte.</span>
                </div>
              </motion.h1>
              <motion.div variants={fadeInUp}>
                <p
                  variants={fadeInUp}
                  className="mb-10 font-lf font-medium text-lg text-gray-900 sm:text-lg leading-normal md:leading-relaxed md:text-xl text-left"
                >
                  We've made it our mission to make our planet plastic-free by
                  giving shape to innovative eco-friendly ideas and to show you
                  by example how you too can make a difference.
                </p>
                <div variants={fadeInUp} className="mb-10">
                  <Link to="/about/" className="button standardButton">
                    our story
                  </Link>
                </div>
              </motion.div>
            </FadeIn>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
