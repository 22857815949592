import { StaticImage } from "gatsby-plugin-image"
import { Autoplay, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { FadeIn } from "../components/animation"
import { MotionDiv } from "../components/animation/elements"
import {
  fadeInDown,
  fadeInLeft,
  fadeInRight,
  fadeInUp,
} from "../components/animation/fade"

import CTA from "../components/CTA"
import Hero from "../components/hero"

import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { Marker } from "../utils"
import Testimonial from "../components/testimonial"
import Service from "../components/service"
/*! purgecss start ignore */
import "swiper/css"
import "swiper/css/effect-fade"
/*! purgecss end ignore */

import { serviceContent, testimonialContent } from "../../content/partials"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <AboutSection />
    <TestimonialSection />
    <ServicesSection />
    <CTA />
  </Layout>
)

const AboutSection = () => (
  <Container>
    <FadeIn className="flex flex-col lg:flex-row justify-between">
      <MotionDiv
        variant={fadeInUp}
        className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3"
      >
        <div className="w-full lg:max-w-2xl">
          <span className="sectionHeading mb-2 text-green-800">Features</span>
          <h2 className="subheading mb-6 md:mb-8">
            <div className="md:flex-wrap md:inline mr-2">
              Why you should choose
            </div>
            our paper bags?
          </h2>
        </div>
      </MotionDiv>
      <MotionDiv variant={fadeInRight} className="sectionText w-full lg:w-1/2">
        <p>
          Kaveri Enterprises is one of the leading manufacturers, suppliers and
          exporters of a wide range of paper bags. We design and produce custom
          printed paper bags from a large stock of paper textures, sizes and
          colours to cater to the diverse requirements of our customers.
        </p>
        <p>
          We have a modernised manufacturing unit run by a team of experienced
          professionals so that we can ensure the products are delivered on time
          with highest standards.
        </p>
      </MotionDiv>
    </FadeIn>

    {/* Mosaic */}
    <FadeIn tag="div" className="mx-auto my-6 lg:my-20">
      <div className="featuredProductsGrid grid-flow-col grid-cols-2 space-y-3 overflow-hidden lg:grid lg:grid-rows-2 lg:gap-3 lg:space-y-0">
        <MotionDiv
          variant={fadeInLeft}
          className="group relative col-span-1 row-span-2 w-full overflow-hidden rounded-lg"
        >
          <StaticImage
            src="https://images.unsplash.com/photo-1561203326-d65d921b5939?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop"
            alt="image"
            className="h-full max-h-64 sm:max-h-96 w-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-110 lg:max-h-full"
          />
          <div className="absolute top-0 left-0 z-10 flex h-full w-full items-end">
            <div className="flex flex-wrap items-center p-3 sm:p-5">
              <div className="flex items-center">
                <Link
                  to="/products/shopping-bags/"
                  className="featuredProductButton"
                >
                  Shopping Bags
                </Link>
              </div>
            </div>
          </div>
        </MotionDiv>

        <MotionDiv variant={fadeInDown} className="grid grid-cols-2 gap-3">
          <div className="group relative w-full overflow-hidden rounded-lg">
            <StaticImage
              src="../images/featured-4.jpg"
              alt="image"
              className="h-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-110"
            />
            <div className="absolute top-0 left-0 z-10 flex h-full w-full items-end">
              <div className="flex flex-wrap items-center p-3 sm:p-5">
                <div className="flex items-center">
                  <Link
                    to="/products/grocery-bags/"
                    className="featuredProductButton"
                  >
                    Grocery Bags
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="group relative w-full overflow-hidden rounded-lg">
            <StaticImage
              src="https://images.unsplash.com/photo-1559794872-d3bd697d45b8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop"
              alt="image"
              className="h-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-110"
            />
            <div className="absolute top-0 left-0 z-10 flex h-full w-full items-end">
              <div className="flex flex-wrap items-center p-3 sm:p-5">
                <div className="flex items-center">
                  <Link
                    to="/products/takeaway-bags/"
                    className="featuredProductButton"
                  >
                    Takeaway Bags
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </MotionDiv>

        <MotionDiv
          variant={fadeInRight}
          className="group relative w-full overflow-hidden rounded-lg"
        >
          <StaticImage
            src="../images/featured-5.jpg"
            className="h-full max-h-48 sm:max-h-64 w-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-110 lg:max-h-full"
            alt="image"
          />
          <div className="absolute top-0 left-0 z-10 flex h-full w-full items-end">
            <div className="flex flex-wrap items-center p-3 sm:p-5">
              <div className="flex items-center">
                <Link
                  to="/products/cake-bags/"
                  className="featuredProductButton"
                >
                  Cake & Biriyani Bags
                </Link>
              </div>
            </div>
          </div>
        </MotionDiv>
      </div>
    </FadeIn>

    <FadeIn>
      <MotionDiv variant={fadeInUp} className="flex justify-center">
        <Link className="button altButton" to="/products/">
          explore our products
        </Link>
      </MotionDiv>
    </FadeIn>
  </Container>
)

const TestimonialSection = () => (
  <Container className="bg-blue-100 relative">
    <FadeIn tag="div">
      <MotionDiv variant={fadeInUp} className="flex flex-wrap">
        <div className="mx-4 w-full">
          <div className="mx-auto mb-6 max-w-[620px] text-center lg:mb-20">
            <span className="sectionHeading mb-2 block font-rw text-lg font-bold uppercase">
              Testimonials
            </span>
            <h2 className="subheading mb-4">
              Why our clients <Marker>trust us</Marker>
            </h2>
            <div className="sectionText">
              <p>The highest standards, the happiest customers</p>
            </div>
          </div>
        </div>
      </MotionDiv>

      <MotionDiv variant={fadeInLeft} className="flex justify-center">
        <Swiper
          spaceBetween={24}
          modules={[Autoplay, EffectFade]}
          effect="fade"
          autoplay={{
            loop: true,
            delay: 5000,
            disableOnInteraction: false,
          }}
          className="testimonialSlider mb-8 w-full md:w-2/3 lg:mb-20 lg:w-1/2"
        >
          {testimonialContent.map(testimonial => (
            <SwiperSlide
              key={testimonial.name}
              className="rounded-xl bg-white shadow-sm"
            >
              <Testimonial {...testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </MotionDiv>

      <div className="flex flex-wrap">
        <div className="mx-4 w-full">
          <FadeIn
            tag="div"
            className="ud-brands-logo flex flex-nowrap items-center justify-center"
          >
            <MotionDiv
              variant={fadeInDown}
              className="ud-single-logo mr-5 max-w-[140px] lg:mr-10"
            >
              <StaticImage src="../images/mirage.svg" alt="tailgrids" />
            </MotionDiv>
            <MotionDiv
              variant={fadeInDown}
              className="ud-single-logo mr-5 max-w-[140px] lg:mr-10"
            >
              <StaticImage src="../images/laravel.svg" alt="ecommerce-html" />
            </MotionDiv>
            <MotionDiv
              variant={fadeInDown}
              className="ud-single-logo mr-5 max-w-[140px] lg:mr-10"
            >
              <StaticImage src="../images/statamic.svg" alt="lineicons" />
            </MotionDiv>
          </FadeIn>
        </div>
      </div>
    </FadeIn>
  </Container>
)

const ServicesSection = () => (
  <Container className="bg-white">
    <FadeIn tag="div">
      <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4">
          <MotionDiv
            variant={fadeInUp}
            className="mx-auto mb-[60px] max-w-[620px] text-center"
          >
            <span className="sectionHeading mb-2 block font-rw text-lg font-bold uppercase">
              About
            </span>
            <h2 className="subheading mb-4">
              Why you should <Marker>choose us</Marker>
            </h2>
            <div className="sectionText">
              <p>
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </MotionDiv>
        </div>
      </div>

      <FadeIn tag="div" className="-mx-4 flex flex-wrap">
        {serviceContent.map(service => (
          <Service
            key={service.title}
            {...service}
            // title={service.title}
            // icon={service.icon}
            // description={service.description}
          />
        ))}
      </FadeIn>

      <MotionDiv variant={fadeInUp} className="flex justify-center">
        <Link className="button altButton" to="/about/">
          Learn more about us
        </Link>
      </MotionDiv>
    </FadeIn>
  </Container>
)

export default IndexPage
