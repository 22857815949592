import React from "react"
import { MotionDiv } from "./animation/elements"
import { fadeInLeft } from "../components/animation/fade"

function Service({ icon, title, description }) {
  return (
    <div className="w-full px-4 md:w-1/2 lg:w-1/4">
      <MotionDiv
        variant={fadeInLeft}
        className="group mb-12 text-center lg:mb-20"
      >
        <div className="flex justify-center">
          <div className="relative z-10 mb-8 flex h-24 w-24 items-center justify-center rounded-full bg-lime-300">
            <span className="absolute top-0 left-0 z-[-1] mb-8 flex h-24 w-24 items-center justify-center rounded-full bg-green-700 bg-opacity-20 duration-300 group-hover:scale-125"></span>

            <img
              src={icon}
              className="w-11 duration-500 ease-in-out group-hover:scale-90"
              alt="icon"
            />
          </div>
        </div>

        <h4 className="columnheading mb-3">{title}</h4>
        <div className="columntext mb-8">
          <p>{description}</p>
        </div>
      </MotionDiv>
    </div>
  )
}

export default Service
