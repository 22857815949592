import React from "react"

function Testimonial({ name, designation, thumbnail, text }) {
  return (
    <div className="ud-single-testimonial p-10 font-rw">
      <div className="ud-testimonial-content mb-6">
        <p className="text-base font-medium tracking-wide text-gray-700">
          {text}
        </p>
      </div>
      <div className="ud-testimonial-info flex items-center">
        <div className="ud-testimonial-image mr-2 h-[50px] w-[50px] overflow-hidden rounded-full">
          <img src={thumbnail} alt={name} />
        </div>
        <div className="ud-testimonial-meta">
          <h4 className="text-sm font-semibold">{name}</h4>
          <p className="text-xs text-[#969696]">{designation}</p>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
