export const testimonialContent = [
  {
    name: "Gustavo Fring",
    designation: "Founder, Los Pollos Hermanos",
    thumbnail: "../images/author-01.png",
    text: `Our members are so impressed. It's intuitive. It's clean. It's
    very distraction free. If you're building a community.`,
  },
  {
    name: "Tuco Salamanca",
    designation: "Founder, TucoCorp",
    thumbnail: "../images/author-02.png",
    text: `Perfectly made. On time delivery. Highly suggestible.`,
  },
  {
    name: "Lydia Rodart",
    designation: "Logistics Head, Madrigal Electromotive",
    thumbnail: "../images/author-01.png",
    text: `Our members are so impressed. It's intuitive. It's clean. It's
    very distraction free. If you're building a community.`,
  },
]

export const serviceContent = [
  {
    title: "We're ever so passionate",
    description: `We are committed to protect the environment and are trying to
    provide as many alternatives to plastic bags using paper bags
    and other paper based products`,
    icon: "../images/ecoicon.png",
  },
  {
    title: "High Quality Printing",
    description: `We make use of high quality digital printers to bring your imagined design to fruition. You can choose quality paper bags from different sizes, paper texture and bag handles.`,
    icon: "../images/printer.png",
  },
  {
    title: "Fast and on-time delivery",
    description: `Our team guarantees a full-time personal approach to your
    requirements and delivers pixel perfect prints in the shortest time.`,
    icon: "../images/fast.png",
  },
  {
    title: "Affordable Pricing",
    description: `We design and produce custom printed paper bags so that you can add your own branding and marketing slogans at affordable rates.`,
    icon: "../images/value.png",
  },
]
